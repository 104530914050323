<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <AreaForm ref="editForm" :initial="initial" class="area-content"></AreaForm>
    </div>
    <a-space class="footer">
      <a-button @click="back">取消</a-button>
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import AreaForm from "./components/AreaForm.vue";

export default {
  name: "NicheAreaDetail",
  components: {
    AreaForm,
  },
  data() {
    return {
      initial: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/mini-worship-area/${this.id}`,
          method: "GET",
          params: {
            expand: "buddhistNiche",
          },
          noTempleFilter: true,
        }).then((res) => {
          res.buddhist_niche_id = res.buddhistNiche;
          this.initial = res;
        });
      }
    },
    handleEdit() {
      this.edit = true;
    },
    handleSave() {
      this.$refs.editForm.validate().then((form) => {
        this.$axios({
          url: `/admin/mini-worship-area/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.back();
        });
      });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
</style>
